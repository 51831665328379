@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;0,700;0,800;1,300&family=Outfit:wght@300;400;500;600;700;800&display=swap');
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Outfit', sans-serif;
}
body{
  background-color: black;
}
 h1,h4,p,a{
  color: white;
  text-decoration: none;
} 
ul{
  list-style-type: none;
}
.btn{
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background:rgb(248,217,15);
  color:#222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}
.btn-light{
  /* background: rgba(255,255,255,0.2); */
  background: transparent;
  color: #fff;
}
.btn:hover{
   background: rgba(255,255,255,0.2); 
  color: #fff;
  transition: 0.3s;
}